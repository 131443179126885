import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { Error } from "@mui/icons-material";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import CardTopic from "components/Cards/CardTopic";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";
import MDSnackbar from "components/MDSnackbar";

import {
  GiChecklist,
  GiTwoShadows,
  GiChatBubble,
  GiPiggyBank,
  GiThunderBlade,
  GiTiedScroll,
} from "react-icons/gi";

import bg1 from "assets/images/bg1.jpg";
import bg2 from "assets/images/bg2.png";
import bg3 from "assets/images/bg3.jpg";
import bg5 from "assets/images/bg5.jpg";

import useAuth from "hooks/useAuth";
import CastleSiegeConfirmation from "./components/CastleSiegeConfirmation";
import UpConfirmation from "./components/UpConfirmation";
import Position from "./components/Position";

function Dashboard() {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const { csWon, user, position, setLoaded } = useAuth();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openUpConfirm, setOpenUpConfirm] = useState(false);
  const [openPosition, setOpenPosition] = useState(false);

  const [error, setError] = useState(false);

  const cacheImages = async () => {
    setLoaded(false);
    const promises = await [bg1, bg2, bg3, bg5].map((imgLink) => {
      return new Promise((res, rej) => {
        const img = new Image();
        img.src = imgLink;
        img.onload = res();
        img.onerror = rej();
      });
    });

    await Promise.all(promises);
    setLoaded(true);
  };

  useEffect(() => {
    cacheImages();
  }, []);

  const handleConfirm = () => {
    if (!user?.csConfirmed) {
      setOpenConfirm(true);
    }
  };

  const handleUpConfirm = () => {
    setOpenUpConfirm(true);
  };

  const handleOpenPosition = () => {
    if (position?.id) {
      setOpenPosition(true);
    }
  };

  useEffect(() => {
    if (!openPosition) {
      if (
        new Date().getDay() === 0 &&
        !!position?.id &&
        !sessionStorage.getItem("positionViewed")
      ) {
        setOpenPosition(true);
        sessionStorage.setItem("positionViewed", true);
      }
    }
  }, [position]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {!!csWon && (
            <>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color={sidenavColor}
                    icon={<GiPiggyBank size={32} />}
                    title="Sua meta"
                    count={user?.meta ? "Pago" : "Não pago"}
                    {...(!user?.meta && {
                      action: {
                        label: "Procure um staff para pagar a sua meta",
                        route: "/",
                      },
                    })}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color={sidenavColor}
                    icon={<GiTiedScroll size={32} />}
                    title="Personagens de Land"
                    count={user?.upConfirmed ? "Informados" : "Não informado"}
                    {...(!user?.upConfirmed && {
                      action: {
                        label: "Informe seus personagens abaixo",
                        action: handleUpConfirm,
                      },
                    })}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color={sidenavColor}
                    icon={<GiThunderBlade size={32} />}
                    title="Situação de Land"
                    count={user?.meta && user?.csConfirmed ? "Liberado" : "Não liberado"}
                    {...((!user?.meta || !user?.csConfirmed) && {
                      action: {
                        label: `${!user?.meta ? "Procure um staff para pagar a sua meta. " : ""}${
                          !user?.csConfirmed ? "Confirme sua presença para o CS." : ""
                        }`,
                        route: "/",
                      },
                    })}
                  />
                </MDBox>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                onClick={handleConfirm}
                sx={{
                  cursor: "pointer",
                }}
                color={sidenavColor}
                icon={<GiChecklist size={50} />}
                title="Confirmações"
                count={user?.confirmations}
                action={{
                  action: handleConfirm,
                  color: sidenavColor,
                  label: user?.csConfirmed
                    ? "Parabéns! Você já confirmou sua presença :)"
                    : "Confirmar presença para o CS",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color={sidenavColor}
                icon={<GiTwoShadows size={40} />}
                title="Membros"
                count={user?.members}
                action={{
                  route: "/members",
                  color: sidenavColor,
                  label: "Ver membros",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color={sidenavColor}
                icon={<GiChatBubble size={40} />}
                title="Tópicos"
                count={user?.topics}
                action={{
                  route: "/forum",
                  color: sidenavColor,
                  label: "Ver tópicos",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            {!!csWon && (
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <CardTopic
                    imgSrc={bg1}
                    title="Land of Trials"
                    active={user?.upConfirmed}
                    description={
                      user?.upConfirmed
                        ? "Clique aqui para editar seu personagem de UP."
                        : "Informar personagem de up e boss em Land Of Trials."
                    }
                    date={
                      user?.upConfirmed
                        ? "Você já informou um personagem de UP."
                        : "Clique aqui e informe seu personagem de UP."
                    }
                    onClick={handleUpConfirm}
                  />
                </MDBox>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <CardTopic
                  imgSrc={bg2}
                  title="Posicionamento"
                  description="Ver posicionamento do Castle Siege"
                  date={`O posicionamento para o Castle Siege ${
                    position?.id ? "já" : "ainda não"
                  } está disponível`}
                  onClick={handleOpenPosition}
                  active={!!position?.id}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <CardTopic
                  active
                  imgSrc={bg5}
                  title="Campeonato de Castle Siege do ZenMU"
                  description="Acompanhe o campeonato de Castle Siege que está em andamento"
                  date="Clique aqui para visualizar o ranking"
                  onClick={() =>
                    window.open(
                      "https://forum.zenmu.com.br/sguncategorized/castle-siege/188-rank-do-campeonato-de-castle-siege",
                      "_blank"
                    )
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <CardTopic
                  imgSrc={bg3}
                  title="Vídeos"
                  description="Assistir ou postar vídeos do Castle Siege"
                  date="Último vídeo postado por:"
                  onClick={() => window.alert("Em construção!")}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      {!!user && (
        <CastleSiegeConfirmation open={openConfirm} setOpen={setOpenConfirm} setError={setError} />
      )}
      {!!user && (
        <UpConfirmation open={openUpConfirm} setOpen={setOpenUpConfirm} setError={setError} />
      )}
      <Position open={openPosition} onClose={() => setOpenPosition(false)} />
      <MDSnackbar
        open={!!error}
        close={() => setError(false)}
        icon={<Error color="white" />}
        color="error"
        title="Ocorreu um erro!"
        content={error}
      />
    </DashboardLayout>
  );
}

export default Dashboard;
