import { useState } from "react";
import { filter, find } from "lodash";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
} from "@mui/material";

import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect";
import ConfirmationForm from "components/ConfirmationForm";

import service from "service";
import useAdmin from "hooks/useAdmin";

function CastleSiegeConfirmation({ setOpen, open, setError }) {
  const { users, loadConfirmations } = useAdmin();
  const [loading, setLoading] = useState();
  const [userConfirm, setUserConfirm] = useState();

  const onClose = () => setOpen(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await service.castleSiege.confirmUser(userConfirm?.id, { data });
      await loadConfirmations();
      setLoading(false);
      setUserConfirm();
      onClose();
    } catch (err) {
      setLoading(false);
      setError(
        err?.response?.data?.error || "Ocorreu um erro inesperado. Tente novamente mais tarde."
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Confirmar presença</DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={2}>
          <MDSelect
            onChange={(e) => {
              setUserConfirm(find(users, { id: e.target.value }));
              return e;
            }}
            defaultValue=""
            variant="standard"
            label="Membro"
            error={!userConfirm}
            fullWidth
          >
            {filter(users, { csConfirmed: false }).map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </MDSelect>
        </Box>
        {!!userConfirm && (
          <ConfirmationForm
            characters={filter(userConfirm.characters, { status: 0 })}
            user={userConfirm}
            onSubmit={onSubmit}
            formId="confirm-mine"
          />
        )}
      </DialogContent>
      <DialogActions>
        <MDButton disabled={loading} variant="outlined" color="error" onClick={onClose}>
          Cancelar
        </MDButton>
        <MDButton
          disabled={loading}
          variant="contained"
          color="success"
          type="submit"
          form="confirm-mine"
        >
          Confirmar presença
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default CastleSiegeConfirmation;
