import api from "./api";

const confirm = async (data) => {
  const response = await api.post("/castle_siege/confirm", data);

  return response;
};

const confirmUser = async (userId, data) => {
  const response = await api.post(`/castle_siege/confirm/${userId}`, data);

  return response;
};

const listConfirmations = async () => {
  const response = await api.get("/castle_siege/confirmations");

  return response;
};

const resetConfirmations = async () => {
  const response = await api.delete("/castle_siege/confirmations");

  return response;
};

const lastCsList = async () => {
  const response = await api.get("/castle_siege/confirmables");

  return response;
};

const confirmMeta = async (historyId, userConfirmationId) => {
  const response = await api.put(`/castle_siege/confirm_meta/${historyId}/${userConfirmationId}`);

  return response;
};

const destroyHistory = async (historyId, userConfirmationId) => {
  const response = await api.delete(`/castle_siege/${historyId}/${userConfirmationId}`);

  return response;
};

const characters = {
  confirm,
  confirmMeta,
  confirmUser,
  listConfirmations,
  resetConfirmations,
  lastCsList,
  destroyHistory,
};

export default characters;
