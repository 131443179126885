import authentication from "./authentication";
import characters from "./characters";
import castleSiege from "./castleSiege";
import topics from "./topics";
import password from "./password";
import position from "./position";
import upConfirmation from "./upConfirmation";
import users from "./users";

const service = {
  authentication,
  characters,
  castleSiege,
  password,
  position,
  upConfirmation,
  topics,
  users,
};

export default service;
