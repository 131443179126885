import { Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import constants from "helpers/constants";

const { ICON_TAGS } = constants.positions;

function PositionBox({ position, color, showPosition = false, mr = 1, textColor = "white" }) {
  return (
    <MDBox
      key={position.id}
      mr={mr}
      mb={1}
      p={1}
      variant="gradient"
      display="flex"
      justifyContent="space-between"
      borderRadius="sm"
      bgColor={color}
      coloredShadow={color}
      gap={1}
    >
      <MDBox
        display="flex"
        {...(showPosition
          ? { flexDirection: "column", justifyContent: "center" }
          : { alignItems: "center" })}
        width={showPosition ? "45%" : "50%"}
      >
        <MDTypography variant="h6" color={textColor}>
          {position.character?.name}
        </MDTypography>
        {showPosition && (
          <MDTypography variant="caption" color={textColor}>
            {position.position}
          </MDTypography>
        )}
      </MDBox>
      {showPosition && (
        <MDBox
          display="flex"
          flexDirection="row-reverse"
          width="20%"
          justifyContent="flex-end"
          alignSelf="end"
          color="white"
          gap={0.5}
        >
          {position.tags?.map((tag) => (
            <Tooltip title={tag}>
              <div>{ICON_TAGS[tag]}</div>
            </Tooltip>
          ))}
        </MDBox>
      )}
      <MDBox
        width={showPosition ? "35%" : "50%"}
        display="flex"
        alignItems="center"
        justifyContent="end"
      >
        <MDTypography
          variant="h6"
          color={textColor}
          textGradient
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {position.user?.name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default PositionBox;
