import { useState } from "react";

import MDBox from "components/MDBox";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";

import Header from "layouts/panel/components/Header";

import PositionProvider from "providers/PositionProvider";

import Users from "./components/Users";
import CastleSiege from "./components/CastleSiege";
import Land from "./components/Land";

function Panel() {
  const [tabValue, setTabValue] = useState(0);

  return (
    <PositionProvider>
      <DashboardLayout>
        <MDBox mb={2} />
        <Header tabValue={tabValue} setTabValue={setTabValue}>
          {tabValue === 0 && <Users />}
          {tabValue === 1 && <CastleSiege />}
          {tabValue === 2 && <Land />}
        </Header>
        <Footer />
      </DashboardLayout>
    </PositionProvider>
  );
}

export default Panel;
