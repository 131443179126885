import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";

import constants from "helpers/constants";

import schema from "./schema";

function ConfirmationForm({ onSubmit, characters, user, formId }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      attack: {
        character: "",
        position: user?.mission_preference.attack || "",
      },
      defense: {
        character: "",
        position: user?.mission_preference.defense || "",
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography variant="h6">Ataque</MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <MDSelect
                {...field}
                variant="standard"
                label="Personagem"
                error={!!errors?.attack?.character}
                fullWidth
              >
                {characters?.map((character) => (
                  <MenuItem key={character?.id} value={character?.id}>
                    {`${character.name} - ${character.class?.name}`}
                  </MenuItem>
                ))}
              </MDSelect>
            )}
            name="attack[character]"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <MDSelect
                {...field}
                variant="standard"
                label="Missão"
                error={!!errors?.attack?.position}
                fullWidth
              >
                {constants.characters.CHARACTER_ROLES_ATTACK.map((item) => (
                  <MenuItem key={item?.id} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </MDSelect>
            )}
            name="attack[position]"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <MDTypography variant="h6">Defesa</MDTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <MDSelect
                {...field}
                variant="standard"
                label="Personagem"
                error={!!errors?.defense?.character}
                fullWidth
              >
                {characters?.map((character) => (
                  <MenuItem key={character?.id} value={character?.id}>
                    {`${character.name} - ${character.class?.name}`}
                  </MenuItem>
                ))}
              </MDSelect>
            )}
            name="defense[character]"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <MDSelect
                {...field}
                variant="standard"
                label="Missão"
                error={!!errors?.defense?.position}
                fullWidth
              >
                {constants.characters.CHARACTER_ROLES_DEFENSE.map((item) => (
                  <MenuItem key={item?.id} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </MDSelect>
            )}
            name="defense[position]"
            control={control}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default ConfirmationForm;
