import { useContext } from "react";

import AuthContext from "contexts/AuthContext";

const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useAuth should beeing inside a AuthProvider");
  }

  return {
    user: authContext.user,
    setUser: authContext.setUser,
    loaded: authContext.loaded,
    loadData: authContext.loadData,
    characters: authContext.characters,
    position: authContext.position,
    loadPosition: authContext.loadPosition,
    permissions: authContext.permissions,
    loadCharacters: authContext.loadCharacters,
    loadAllData: authContext.loadAllData,
    setLoaded: authContext.setLoaded,
    csWon: true,
  };
};

export default useAuth;
